import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from "primereact/column";
import { routes } from "../../../constants";
import { Page } from "../../../components/page/Page";
import { actions } from "../order-delivery.actions";
import { actions as carrierActions } from "../../carrier/carrier.actions";
import { actions as locationsActions } from "../../location/location.actions";
import { actions as unidadeActions } from "../../unidade/unidade.actions";
import { actions as businessUnitsGroupActions } from "../../businessUnitGroupFilter/businessUnitGroupFilter.actions";

import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { OrderDeliveryList } from "./OrderOrderDeliveryList";
import { Filter } from "../components/Filter";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Wizard } from "../../../components/wizard/Wizard";
import { Step } from "../../../components/step/Step";
import moment from "moment";

const mapStateToProps = (state) => {
  const filterFields = {
    ...state.crudOrderDeliveryState.filterFields,
    businessUnitGroup: state.crudBusinessUnitGroupState.currentRecord,
    dropdownBusinessUnitsOptions: state.crudUnidadeState.records,
  };

  const businessUnitGroupRecords = state.crudBusinessUnitGroupState.records;
  const businessUnitGroupColumns = state.crudBusinessUnitGroupState.columns;
  const businessUnitGroupIndex = state.crudBusinessUnitGroupState.index;
  const businessUnitGroupMax = state.crudBusinessUnitGroupState.max;
  const businessUnitGroupCount = state.crudBusinessUnitGroupState.count;
  const businessUnitGroupSortField = state.crudBusinessUnitGroupState.sortField;
  const businessUnitGroup = state.crudBusinessUnitGroupState.currentRecord;

  const selectedBusinessUnitGroup = state.crudOrderDeliveryState.filterFields.selectedBusinessUnitGroup || null;

  const businessUnitColumns = state.crudUnidadeState.columns;
  const businessUnitRecords = state.crudUnidadeState.records;

  const listGrouper = state.crudOrderDeliveryState.filterFields.listGrouper || null;
  const selectedBusinessUnits = state.crudOrderDeliveryState.filterFields.selectedBusinessUnits || [];
  const selectedMainBusinessUnit = state.crudOrderDeliveryState.filterFields.selectedMainBusinessUnit || null;
  const selectedBusinessUnitGroupLabel = state.crudOrderDeliveryState.filterFields.selectedBusinessUnitGroupLabel || "";

  const lookupCarrierBase = {
    records: state.crudCarrierState.records,
    columns: state.crudOrderDeliveryState.filterFields.lookupCarrier.visible
      ? state.crudCarrierState.columns.filter((col) => col.field === "oid" || col.field === "name" || col.field === "tradeName").map((col) => <Column key={col.field} {...col} />)
      : [<Column key={"columnKeyCarrier"} />],
    first: state.crudCarrierState.index,
    rows: state.crudCarrierState.max,
    totalRecords: state.crudCarrierState.count,
    sortField: state.crudCarrierState.sortField,
    order: state.crudCarrierState.sortOrder,
    rowsPerPageOptions:[20,50,100],
    editDisabled: true,
  };
  const lookupCarrier = { ...state.crudOrderDeliveryState.filterFields.lookupCarrier, ...lookupCarrierBase };

  const lookupDestinationBase = {
    records: state.crudLocationState.records,
    columns: state.crudOrderDeliveryState.filterFields.lookupDestination.visible
      ? state.crudLocationState.columns.filter((col) => col.field === "oid" || col.field === "description").map((col) => <Column key={col.field} {...col} />)
      : [<Column key={"columnKeyDestination"} />],
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: true,
  };
  const lookupDestination = { ...state.crudOrderDeliveryState.filterFields.lookupDestination, ...lookupDestinationBase };

  const lookupOriginBase = {
    records: state.crudLocationState.records,
    columns: state.crudOrderDeliveryState.filterFields.lookupOrigin.visible
      ? state.crudLocationState.columns.filter((col) => col.field === "oid" || col.field === "description").map((col) => <Column key={col.field} {...col} />)
      : [<Column key={"columnKeyOrigin"} />],
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: true,
  };
  const lookupOrigin = { ...state.crudOrderDeliveryState.filterFields.lookupOrigin, ...lookupOriginBase };

  return {
    columns: state.crudOrderDeliveryState.columns,
    records: state.crudOrderDeliveryState.records,
    first: state.crudOrderDeliveryState.index,
    rows: state.crudOrderDeliveryState.max,
    totalRecords: state.crudOrderDeliveryState.count,
    sortField: state.crudOrderDeliveryState.sortField,
    order: state.crudOrderDeliveryState.sortOrder,
    orderFilters: state.crudOrderDeliveryState.orderFilters,

    filterFields: filterFields,
    businessUnitGroupRecords,
    businessUnitGroupColumns,
    businessUnitGroupIndex,
    businessUnitGroupMax,
    businessUnitGroupCount,
    businessUnitGroupSortField,
    businessUnitGroup,
    selectedBusinessUnitGroup,
    businessUnitColumns,
    businessUnitRecords,
    selectedBusinessUnits,
    selectedBusinessUnitGroupLabel,
    selectedMainBusinessUnit,
    lookupCarrier,
    lookupDestinationBase,
    lookupDestination,
    lookupOrigin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),

    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    loadOrders: () => dispatch(actions.load()),

    setBusinessUnitFilter: (businessUnit) => {
      dispatch(actions.setBusinessUnitFilter(businessUnit));
      if (businessUnit) {
        dispatch(businessUnitsGroupActions.setRecord(null));
      }
    },
    loadBusinessUnits: (filters) => {
      dispatch(unidadeActions.applyFilter(filters));
      dispatch(unidadeActions.load());
    },

    setBusinessUnitGroup: (businessUnitGroup) => {
      if (businessUnitGroup) {
        dispatch(actions.setBusinessUnitFilter(null));
      }
      dispatch(businessUnitsGroupActions.setRecord(businessUnitGroup));
    },
    applyBusinessUnitGroupFilter: (filters) => {
      dispatch(businessUnitsGroupActions.setType(null));
      dispatch(businessUnitsGroupActions.applyFilter(filters));
    },
    onShowSearchBusinessUnitModal: (visible) => {
      if (visible) {
        dispatch(businessUnitsGroupActions.load());
      }
      dispatch(actions.setVisibleModalSearchBusinessUnitFilter(visible));
    },

    onShowGroupModal: () => {
      dispatch(businessUnitsGroupActions.setRecord(null));
      dispatch(unidadeActions.load());
      dispatch(actions.setVisibleModalGrouperFilter(true));
    },
    onHideGroupModal: () => {
      dispatch(actions.setVisibleModalGrouperFilter(false));
    },
    setSelectedBusinessUnitGroup: (selectedBusinessUnitGroup) => {
      dispatch(actions.setSelectedBusinessUnit(selectedBusinessUnitGroup));
    },

    setSelectedBusinessUnits: (records) => {
      dispatch(actions.setSelectedBusinessUnits(records));
    },
    setSelectMainBusinessUnit: (record) => {
      dispatch(actions.setSelectMainBusinessUnit(record));
    },

    createBusinessUnitGroup: (newGroup) => {
      if (newGroup) {
        dispatch(actions.setBusinessUnitFilter(null));
      }
      dispatch(businessUnitsGroupActions.setRecord(newGroup));
      dispatch(businessUnitsGroupActions.save());
    },

    setSelectedBusinessUnitGroupLabel: (value) => {
      dispatch(actions.setSelectedBusinessUnitGroupLabel(value));
    },

    businessUnitGroupPaginate: (paginationParams) => dispatch(businessUnitsGroupActions.paginate(paginationParams)),
    businessUnitGroupOnSortOrder: (sortParams) => dispatch(businessUnitsGroupActions.sortOrder(sortParams)),

    //LOOKUP CARRIER
    lookupCarrierLoad: () => dispatch(carrierActions.load()),
    lookupCarrierSetVisible: (visible) => dispatch(actions.setLookupCarrierVisible(visible)),
    lookupCarrierPaginate: (paginationParams) => dispatch(carrierActions.paginate(paginationParams)),
    lookupCarrierSortOrder: (sortParams) => dispatch(carrierActions.sortOrder(sortParams)),
    setCarrierFilter: (carrier) => dispatch(actions.setCarrierFilter(carrier)),
    lookupCarrierApplyFilter: (filterParams) => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams));
    },
    //LOOKUP CARRIER

    //LOOKUP DESTINATION
    lookupDestinationLoad: () => dispatch(locationsActions.load()),
    lookupDestinationSetVisible: (visible) => dispatch(actions.setLookupDestinationVisible(visible)),
    setDestinationFilter: (destination) => dispatch(actions.setDestinationFilter(destination)),
    lookupDestinationApplyFilter: (filterParams) => {
      dispatch(locationsActions.setType(null));
      dispatch(locationsActions.applyFilter(filterParams));
    },
    lookupDestinationPaginate: (paginationParams) => dispatch(locationsActions.paginate(paginationParams)),
    lookupDestinationSortOrder: (sortParams) => dispatch(locationsActions.sortOrder(sortParams)),
    //LOOKUP DESTINATION

    //LOOKUP ORIGEM
    lookupOriginLoad: () => dispatch(locationsActions.load()),
    lookupOriginSetVisible: (visible) => dispatch(actions.setLookupOriginVisible(visible)),
    setOriginFilter: (origin) => dispatch(actions.setOriginFilter(origin)),
    lookupOriginApplyFilter: (filterParams) => {
      dispatch(locationsActions.setType(null));
      dispatch(locationsActions.applyFilter(filterParams));
    },
    lookupOriginPaginate: (paginationParams) => dispatch(locationsActions.paginate(paginationParams)),
    lookupOriginSortOrder: (sortParams) => dispatch(locationsActions.sortOrder(sortParams)),
    //LOOKUP ORIGEM

    initialDateChangeHandler: (e) => dispatch(actions.setInitialDateFilter(e.value)),
    finalDateChangeHandler: (e) => dispatch(actions.setFinalDateFilter(e.value)),
    setNumberFilter: (field, value) => dispatch(actions.setNumberFilter(field, value)),

    downloadDelivery: (filters) => dispatch(actions.downloadDelivery(filters)),
  };
};

class OrderDeliveryListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOrderDialog: false,
      currentOrder: null,
      currentStepIndex: 0,
    };
  }
  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    this.onApplyFilter();
    this.props.loadBusinessUnits([]);
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.onApplyFilter();
      if (eventName === COMPANY_CHANGE_EVENT_NAME) {
        this.props.setBusinessUnitFilter(null);
        this.props.loadBusinessUnits([]);
      }
    }
  };

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };

  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onFilter = (e) => {
    console.log(e);
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    console.log(filters);
    this.props.applyFilter(filters);
  };

  onDropdownBusinessUnitChange = (event) => {
    console.log(event);
    const businessUnit = event.value;
    this.props.setBusinessUnitFilter(businessUnit);
  };

  onChangeBusinessUnitLabel = (event) => {
    const target = event.target;
    const value = target.value;
    this.props.setSelectedBusinessUnitGroupLabel(value);
  };

  onCompleteBusinessUnitGroup = (event) => {};

  onInputFilterBusinessUnitGroup = (event) => {
    const value = event.value;
    let filters = [];
    if (typeof value === "string") {
      filters.push({
        campo: "label",
        valor: value,
        tipoFiltro: "CONTEM",
      });
      this.props.setBusinessUnitGroup({ label: value });
    } else {
      this.props.setBusinessUnitGroup(value);
    }
    this.props.applyBusinessUnitGroupFilter(filters);
  };

  onBusinessUnitsHide = (e) => {
    this.props.onShowSearchBusinessUnitModal(false);
  };

  onClickSearchGrouper = (e) => {
    this.props.onShowSearchBusinessUnitModal(true);
  };
  onClickAddGrouper = (e) => {
    this.props.onShowGroupModal();
  };
  onGroupHide = (e) => {
    this.props.onHideGroupModal();
    return this.setState({ ...this.state, currentStepIndex: 0 });
  };

  businessUnitGroupOnPage = (e) => {
    this.props.businessUnitGroupPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  businessUnitGroupOnOnSort = (e) => {
    this.props.businessUnitGroupOnSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onSelectionBusinessUnitGroupChange = (event) => {
    this.props.setSelectedBusinessUnitGroup(event.value);
  };

  onConfirmSelectedBusinessUnitGroup = () => {
    const businessUnitGroup = this.props.selectedBusinessUnitGroup || null;
    this.props.setBusinessUnitGroup(businessUnitGroup);
    this.props.setSelectedBusinessUnitGroup(null);
    this.props.onShowSearchBusinessUnitModal(false);
  };

  onSelectBusinessUnitsChange = (event) => {
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    this.props.setSelectedBusinessUnits(event.value);
  };

  onSelectMainBusinessUnitsChange = (event) => {
    this.props.setSelectMainBusinessUnit(event.value);
  };

  onNextGrouper = (e) => {
    return this.setState({ ...this.state, currentStepIndex: 1 });
  };

  onPreviousGrouper = (e) => {
    return this.setState({ ...this.state, currentStepIndex: 0 });
  };

  onCancelGrouper = (e) => {
    return this.setState({ ...this.state, currentStepIndex: 0 });
  };

  onConfirmGroup = (e) => {
    let businessUnits = this.props.selectedBusinessUnits || [];
    businessUnits = businessUnits.map((unit) => {
      return {
        id: unit.oid,
        documentNumber: unit.cnpj,
        tradeName: unit.nomePessoa,
        label: unit.codigo,
      };
    });
    const mainBusinessUnit = {
      id: this.props.selectedMainBusinessUnit.oid,
      documentNumber: this.props.selectedMainBusinessUnit.cnpj,
      tradeName: this.props.selectedMainBusinessUnit.nomePessoa,
      label: this.props.selectedMainBusinessUnit.codigo,
    };
    const label = this.props.selectedBusinessUnitGroupLabel;
    this.props.createBusinessUnitGroup({
      businessUnits,
      mainBusinessUnit,
      label,
    });
    this.props.setSelectMainBusinessUnit(null);
    this.props.setSelectedBusinessUnits([]);
    this.props.setSelectedBusinessUnitGroupLabel("");
    this.props.onHideGroupModal();
    this.setState({ ...this.state, currentStepIndex: 0 });
  };

  // LOOKUP CARRIER
  lookupCarrierOnPage = (e) => {
    this.props.lookupCarrierPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupCarrierOnSort = (e) => {
    this.props.lookupCarrierSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupCarrierOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupCarrierApplyFilter(filters);
  };
  lookupCarrierOnClick = (e) => {
    this.props.lookupCarrierLoad();
    this.props.lookupCarrierSetVisible(true);
  };
  lookupCarrierOnHide = (e) => {
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnConfirm = (e) => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.setCarrierFilter(e.selection);
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnCancel = (e) => {
    this.props.setCarrierFilter(null);
    this.props.lookupCarrierSetVisible(false);
  };

  lookupCarrierOnEdit = () => {};

  lookupCarrierOnComplete = (e) => {};

  lookupCarrierOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === "string") {
      filters.push({
        campo: "tradeName",
        valor: value,
        tipoFiltro: "CONTEM",
      });
      this.props.setCarrierFilter({ tradeName: value  });
    } else {
      this.props.setCarrierFilter(value);
    }
    this.props.lookupCarrierApplyFilter(filters);
  };

  getCarrierFilter = () => {
    const { documentNumber } = this.props.filterFields.carrier || "";
    return documentNumber;
  };

  // LOOKUP CARRIER

  // LOOKUP DESTINATION
  lookupDestinationOnPage = (e) => {
    this.props.lookupDestinationPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupDestinationOnSort = (e) => {
    this.props.lookupDestinationSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupDestinationOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupDestinationApplyFilter(filters);
  };
  lookupDestinationOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === "string") {
      filters.push({
        campo: "description",
        valor: value,
        tipoFiltro: "CONTEM",
      });
      this.props.setDestinationFilter({ description: value });
    } else {
      this.props.setDestinationFilter(value);
    }
    this.props.lookupDestinationApplyFilter(filters);
  };
  lookupDestinationOnClick = (e) => {
    this.props.lookupDestinationLoad();
    this.props.lookupDestinationSetVisible(true);
  };
  lookupDestinationOnHide = (e) => {
    this.props.lookupDestinationSetVisible(false);
  };
  lookupDestinationOnConfirm = (e) => {
    this.props.lookupDestinationApplyFilter([]);
    this.props.setDestinationFilter(e.selection);
    this.props.lookupDestinationSetVisible(false);
  };
  lookupDestinationOnCancel = (e) => {
    this.props.setDestinationFilter(null);
    this.props.lookupDestinationSetVisible(false);
  };
  lookupDestinationOnEdit = () => {};
  lookupDestinationOnComplete = (e) => {};
  getDestinationFilter = () => {
    const { oid } = this.props.filterFields.destination || {};
    return oid;
  };
  //LOOKUP DESTINATION

  // LOOKUP ORIGIN
  lookupOriginOnPage = (e) => {
    this.props.lookupOriginPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupOriginOnSort = (e) => {
    this.props.lookupOriginSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupOriginOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupOriginApplyFilter(filters);
  };
  lookupOriginOnClick = (e) => {
    this.props.lookupOriginLoad();
    this.props.lookupOriginSetVisible(true);
  };
  lookupOriginOnHide = (e) => {
    this.props.lookupOriginSetVisible(false);
  };
  lookupOriginOnConfirm = (e) => {
    this.props.lookupOriginApplyFilter([]);
    this.props.setOriginFilter(e.selection);
    this.props.lookupOriginSetVisible(false);
  };
  lookupOriginOnCancel = (e) => {
    this.props.setOriginFilter(null);
    this.props.lookupOriginSetVisible(false);
  };
  lookupOriginOnEdit = () => {};
  lookupOriginOnComplete = (e) => {};
  lookupOriginOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === "string") {
      filters.push({
        campo: "description",
        valor: value,
        tipoFiltro: "CONTEM",
      });
      this.props.setOriginFilter({ description: value });
    } else {
      this.props.setOriginFilter(value);
    }
    this.props.lookupOriginApplyFilter(filters);
  };
  getOriginFilter = () => {
    const { oid } = this.props.filterFields.origin || {};
    return oid;
  };
  // LOOKUP ORIGEM

  onInitialDateChangeHandler = (e) => {
    this.props.initialDateChangeHandler(e);
  };

  onFinalDateChangeHandler = (e) => {
    this.props.finalDateChangeHandler(e);
  };

  onSetNumberFilter = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    this.props.setNumberFilter(field, value);
  };

  getFilters = () => {
    let orderFilters = [];
    const normalizeDate = (date) => {
      return date && typeof date === "object" ? date : null;
    };
    const initialDateFilter = normalizeDate(this.props.filterFields.initialDate);
    const finalDateFilter = normalizeDate(this.props.filterFields.finalDate);
    const number = this.props.filterFields.number && this.props.filterFields.number !== "" ? this.props.filterFields.number : null;
    const carrierOptions = this.getCarrierFilter() || "";
    const originOptions = this.getOriginFilter() || "";
    const destinationOptions = this.getDestinationFilter() || "";
    const groupOption = this.props.filterFields.businessUnitGroup || null;
    const currentBusinessUnit = this.props.filterFields.currentBusinessUnit || null;

    const baseFilters = [];
    if (initialDateFilter && finalDateFilter) {
      baseFilters.push({
        campo: "createdAt",
        campoComplementar: "createdAt",
        valor: initialDateFilter,
        valorComplementar: finalDateFilter,
        tipoFiltro: "ENTRE",
      });
    }
    if (number) {
      let num = number.split(";");
      if (num.length == 1) {
        baseFilters.push({
          campo: "code",
          valor: number,
          tipoFiltro: "IGUAL_A",
        });
      } else {
        baseFilters.push({
          campo: "code",
          valor: num,
          tipoFiltro: "ESTA_CONTIDO_EM",
        });
      }
    }
    if (carrierOptions != "") {
      baseFilters.push({
        campo: "carrierNumber",
        valor: carrierOptions,
        tipoFiltro: "IGUAL_A",
      });
    }
    if (originOptions != "") {
      baseFilters.push({
        campo: "origin.locationID",
        valor: originOptions,
        tipoFiltro: "IGUAL_A",
      });
    }
    if (destinationOptions != "") {
      baseFilters.push({
        campo: "destination.locationID",
        valor: destinationOptions,
        tipoFiltro: "IGUAL_A",
      });
    }

    {
      /** filtros para o AGRUPADOR */
    }
    console.log("currentBusinessUnit=",currentBusinessUnit)
    orderFilters = [...baseFilters];

    if (groupOption && groupOption.oid) {
      orderFilters.push({
        campo: "businessUnit.documentNumber",
        valor: groupOption.businessUnits.map((unit) => unit.documentNumber),
        tipoFiltro: "ESTA_CONTIDO_EM",
      });
      orderFilters.push({
        campo: "businessUnit.label",
        valor: groupOption.businessUnits.map((unit) => unit.code),
        tipoFiltro: "ESTA_CONTIDO_EM",
      });
    } else if (currentBusinessUnit && currentBusinessUnit.documentNumber) {
      orderFilters.push({
        campo: "businessUnit.documentNumber",
        valor: [currentBusinessUnit.documentNumber],
        tipoFiltro: "ESTA_CONTIDO_EM",
      });
      orderFilters.push({
        campo: "businessUnit.label",
        valor: currentBusinessUnit.code,
        tipoFiltro: "IGUAL_A",
      });
    }
    return orderFilters;
  };

  onApplyFilter = () => {
    const filtersParams = this.getFilters();
    this.props.applyFilter(filtersParams);
    this.props.loadOrders();
  };

  onRowClick = (rowData) => {
    this.props.history.push(`${routes.PATH_ORDER_DELIVERY}/${rowData.data.oid}`);
  };

  downloadDelivery = () => {
    const filters = this.getFilters();
    this.props.applyFilter(filters);
    this.props.downloadDelivery(filters);
  };

  render() {
    const actionsFilter = {
      numberChangeHandler: this.onSetNumberFilter,
      initialDateChangeHandler: this.onInitialDateChangeHandler,
      finalDateChangeHandler: this.onFinalDateChangeHandler,
      applyFilter: this.onApplyFilter,
      clickSearchHandler: this.onClickSearchGrouper,
      clickAddHandler: this.onClickAddGrouper,
      onComplete: this.onCompleteBusinessUnitGroup,
      onInputFilter: this.onInputFilterBusinessUnitGroup,
      onDropdownBusinessUnitChange: this.onDropdownBusinessUnitChange,
    };

    const lookupCarrier = {
      ...this.props.lookupCarrier,
      lookupCarrierOnComplete: this.lookupCarrierOnComplete,
      lookupCarrierOnInputFilter: this.lookupCarrierOnInputFilter,
      lookupCarrierOnEdit: this.lookupCarrierOnEdit,
      lookupCarrierOnClick: this.lookupCarrierOnClick,
      lookupCarrierOnConfirm: this.lookupCarrierOnConfirm,
      lookupCarrierOnCancel: this.lookupCarrierOnCancel,
      lookupCarrierOnPage: this.lookupCarrierOnPage,
      lookupCarrierOnFilter: this.lookupCarrierOnFilter,
      lookupCarrierOnSort: this.lookupCarrierOnSort,
      lookupCarrierOnHide: this.lookupCarrierOnHide,
    };

    const lookupDestination = {
      ...this.props.lookupDestination,
      lookupDestinationOnComplete: this.lookupDestinationOnComplete,
      lookupDestinationOnInputFilter: this.lookupDestinationOnInputFilter,
      lookupDestinationOnEdit: this.lookupDestinationOnEdit,
      lookupDestinationOnClick: this.lookupDestinationOnClick,
      lookupDestinationOnConfirm: this.lookupDestinationOnConfirm,
      lookupDestinationOnCancel: this.lookupDestinationOnCancel,
      lookupDestinationOnPage: this.lookupDestinationOnPage,
      lookupDestinationOnFilter: this.lookupDestinationOnFilter,
      lookupDestinationOnSort: this.lookupDestinationOnSort,
      lookupDestinationOnHide: this.lookupDestinationOnHide,
    };
    const lookupOrigin = {
      ...this.props.lookupOrigin,
      lookupOriginOnComplete: this.lookupOriginOnComplete,
      lookupOriginOnInputFilter: this.lookupOriginOnInputFilter,
      lookupOriginOnEdit: this.lookupOriginOnEdit,
      lookupOriginOnClick: this.lookupOriginOnClick,
      lookupOriginOnConfirm: this.lookupOriginOnConfirm,
      lookupOriginOnCancel: this.lookupOriginOnCancel,
      lookupOriginOnPage: this.lookupOriginOnPage,
      lookupOriginOnFilter: this.lookupOriginOnFilter,
      lookupOriginOnSort: this.lookupOriginOnSort,
      lookupOriginOnHide: this.lookupOriginOnHide,
    };

    const dateTemplate = (rowData, column) => {
      const date = rowData[column.field];
      let formattedDate = moment(date).isValid() ? moment(date).add(3, 'hours').format("DD/MM/YYYY HH:mm") : "";
      return <div style={{ textAlign: "center", color: "black" }}>{formattedDate}</div>;
    };

    const statusTemplate = (rowData, column) => {
      let status = "";

      if (rowData.scriptStatus === "NOT_SCRIPTED") {
        if (rowData.readyStock === false) {
          status = "Pedido sem estoque";
        } else {
          status = "Pedido com estoque";
        }
      } else if (rowData.scriptStatus === "SCRIPTING") {
        status = "Em roteirização";
      } else if (rowData.scriptStatus === "SCRIPTED") {
        status = "Roteirizado";
      }
      return <div style={{ textAlign: "center", color: "black" }}>{status}</div>;
    };

    const columns = this.props.columns.map((col) => {
      if (col.field === "createdAt") {
        return <Column {...col} key={col.field} body={dateTemplate} />;
      }

      if (col.field === "deliveryForecast") {
        return <Column {...col} key={col.field} body={dateTemplate} />;
      }
      if (col.field === "scriptStatus") {
        return <Column {...col} key={col.field} body={statusTemplate} />;
      }

      return <Column {...col} key={col.field} />;
    });

    const content = (
      <div className={"order-delivery"}>
        <OrderDeliveryList {...this.props} onFilter={this.onFilter} onPage={this.onPage} onRowClick={this.onRowClick} onSort={this.onSort} columns={columns} />
      </div>
    );

    let columnsBusinessUnitsGrouper = this.props.businessUnitColumns
      .map((col) => {
        col.filter = false;
        col.sortable = false;
        return col;
      })
      .filter((col) => col.field === "oid" || col.field === "nomePessoa" || col.field === "sigla")
      .map((col) => {
        if (col.field === "oid") {
          return <Column {...col} key={col.field} field={col.field} header="Código" />;
        }
        if (col.field === "nomePessoa") {
          return <Column {...col} key={col.field} field={col.field} header="Unidade" />;
        }
        if (col.field === "label") {
          return <Column {...col} key={col.field} field={col.field} header="Label" />;
        }
        return <Column {...col} key={col.field} field={col.field} header={col.field} />;
      });
    const selectorUnitsColumns = <Column key="selectorColumn" selectionMode="multiple" style={{ width: "3em" }} />;
    const selectorMainUnitColumns = <Column key="selectorColumn" selectionMode="single" style={{ width: "3em" }} />;

    columnsBusinessUnitsGrouper =
      this.state.currentStepIndex === 0 ? [selectorUnitsColumns, ...columnsBusinessUnitsGrouper] : [selectorMainUnitColumns, ...columnsBusinessUnitsGrouper];

    const contentStep1 = (
      <div className="p-col-12">
        <label>Selecione as Unidades</label>
        <DataTable
          className="lxUnitsTable"
          selectionMode="multiple"
          onFilter={() => {}}
          onSort={() => {}}
          onPage={() => {}}
          value={this.props.businessUnitRecords}
          lazy={true}
          // totalRecords={props.totalRecords}
          // sortOrder={props.order}
          // sortField={props.sortField}
          // paginator={true}
          // first={props.first}
          // rows={props.rows}
          scrollHeight={"320px"}
          scrollable={true}
          selection={this.props.selectedBusinessUnits}
          responsive={true}
          emptyMessage="Não foram encontrados registros"
          rowsPerPageOptions={[5, 10, 20]}
          onSelectionChange={this.onSelectBusinessUnitsChange}
        >
          {columnsBusinessUnitsGrouper}
        </DataTable>
      </div>
    );

    const contentStep2 = (
      <React.Fragment>
        <div className="p-col-12 form-field-container">
          <label>label</label>
          <InputText value={this.props.selectedBusinessUnitGroupLabel} onChange={this.onChangeBusinessUnitLabel} name="businessUnitLabel" />
        </div>
        <div className="p-col-12">
          <label>Selecione a unidade principal</label>
          <DataTable
            className="lxUnitsTable"
            selectionMode="multiple"
            onFilter={() => {}}
            onSort={() => {}}
            onPage={() => {}}
            value={this.props.selectedBusinessUnits}
            lazy={true}
            // totalRecords={props.totalRecords}
            // sortOrder={props.order}
            // sortField={props.sortField}
            // paginator={true}
            // first={props.first}
            // rows={props.rows}
            scrollHeight={"320px"}
            scrollable={true}
            selection={this.props.selectedMainBusinessUnit}
            responsive={true}
            emptyMessage="Não foram encontrados registros"
            rowsPerPageOptions={[5, 10, 20]}
            onSelectionChange={this.onSelectMainBusinessUnitsChange}
          >
            {columnsBusinessUnitsGrouper}
          </DataTable>
        </div>
      </React.Fragment>
    );

    const grouper = (
      <Wizard
        header="Agrupador"
        selectionMode="multiple"
        visible={this.props.filterFields.modalGrouperVisible}
        modal={true}
        currentIndex={this.state.currentStepIndex}
        onHide={this.onGroupHide}
      >
        <Step content={contentStep1} onClickNext={this.onNextGrouper}></Step>
        <Step
          content={contentStep2}
          onClickPrevious={this.onPreviousGrouper}
          onClickConfirm={this.onConfirmGroup}
          disableConfirm={!this.props.selectedMainBusinessUnit || (this.props.selectedBusinessUnitGroupLabel && this.props.selectedBusinessUnitGroupLabel.trim() === "")}
          onClickCancel={this.onCancelGrouper}
        ></Step>
      </Wizard>
    );

    const footerBusinessUnit = (
      <div className="footer_businessUnit" style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          <button className="lognex-btn-highlighted" title="Confirmar" onClick={this.onConfirmSelectedBusinessUnitGroup}>
            <i class="pi pi-check"></i>
          </button>
        </div>
        <div>
          <button className="lognex-btn-danger" title="Cancelar" onClick={this.onBusinessUnitsHide}>
            <i class="pi pi-times"></i>
          </button>
        </div>
      </div>
    );

    let columnsBusinessUnits = this.props.businessUnitGroupColumns
      .filter((col) => col.field != "oid")
      .map((col) => {
        if (col.field === "label") {
          return <Column {...col} key={col.field} field={col.field} header="Grupo" />;
        }
        if (col.field === "mainBusinessUnit") {
          return <Column {...col} key={col.field} field={col.field} header="Principal" body={(rowData) => <span>{rowData.mainBusinessUnit.label}</span>} />;
        }
        if (col.field === "businessUnits") {
          return <Column {...col} key={col.field} field={col.field} header="Unidades" body={(rowData) => <span> {rowData.businessUnits.map((u) => u.label).join(", ")}</span>} />;
        }
        return <Column {...col} key={col.field} field={col.field} header={col.field} />;
      });
    const selectorBusinessUnitColumns = <Column key="selectorColumn" selectionMode="single" style={{ width: "3em" }} />;
    columnsBusinessUnits = [selectorBusinessUnitColumns, ...columnsBusinessUnits];

    const contentBusinessUnits = (
      <div className="p-col-12">
        <DataTable
          className="lxUnitsTable"
          selectionMode="single"
          onPage={this.businessUnitGroupOnPage}
          value={this.props.businessUnitGroupRecords}
          lazy={true}
          sortOrder={this.businessUnitGroupOnOnSort}
          sortField={this.props.businessUnitGroupSortField}
          paginator={true}
          first={this.props.businessUnitGroupIndex}
          rows={this.props.businessUnitGroupMax}
          totalRecords={this.props.businessUnitGroupCount}
          selection={this.props.selectedBusinessUnitGroup}
          responsive={true}
          emptyMessage="Não foram encontrados registros"
          rowsPerPageOptions={[5, 10, 20]}
          onSelectionChange={this.onSelectionBusinessUnitGroupChange}
        >
          {columnsBusinessUnits}
        </DataTable>
      </div>
    );

    const searchBusinessUnits = (
      <Dialog
        className="lxStepDialog"
        selectionMode="single"
        header="Unidades"
        visible={this.props.filterFields.modalSearchBusinessUnitVisible}
        modal={true}
        footer={footerBusinessUnit}
        onHide={this.onBusinessUnitsHide}
      >
        {contentBusinessUnits}
      </Dialog>
    );

    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Ordens</h1>
        </div>
        <hr className="lxSplitter"></hr>
      </div>
    );

    const contentWrapper = (
      <div>
        <Filter
          filterFields={this.props.filterFields}
          actions={actionsFilter}
          lookupCarrier={lookupCarrier}
          lookupOrigin={lookupOrigin}
          lookupDestination={lookupDestination}
          records={this.props.businessUnitGroupRecords}
          onDownloadCSV={this.downloadDelivery}
        />

        {searchBusinessUnits}

        {grouper}

        {content}
      </div>
    );

    return <Page header={header} content={contentWrapper} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDeliveryListPageContainer);
