import { types } from "./tracking.actions";
import { Reducer } from "../../services/reducer.factory";
import moment from "moment";

const initialState = {
  columns2: [
    {
      field: "deliveryStatus",
      header: "Situação",
      columnKey: "deliveryStatus",
      filter: true,
    },
    {
      field: "totalOccurrences",
      header: "Ocorrências",
      columnKey: "totalOccurrences",
      filter: true,
    },
    {
      field: "number",
      header: "Número",
      columnKey: "number",
      filter: true,
    },
    {
      field: "sequence",
      header: "Série",
      columnKey: "sequence",
      filter: true,
    },
    {
      field: "issueDate",
      header: "Dt Emissão",
      columnKey: "issueDate",
      filter: true,
    },
    {
      field: "deliveryDeadlineDate",
      header: "Dt Previsão",
      columnKey: "deliveryDeadlineDate",
      filter: true,
    },
    {
      field: "deliveryDate",
      header: "Dt Entrega",
      columnKey: "deliveryDate",
      filter: false,
    },
    {
      field: "receiver",
      header: "Destinatário",
      columnKey: "receiver",
      filter: true,
    },
    {
      field: "destination",
      header: "Destino",
      columnKey: "destination",
      filter: true,
    },
    {
      field: "carrier.name",
      header: "Transportadora",
      columnKey: "carrier.cnpj",
      filter: false,
    },
    {
      field: "cubage",
      header: "Cubagem",
      columnKey: "cubage",
      filter: false,
    },
    {
      field: "vehicleType",
      header: "Tipo Veículo",
      columnKey: "vehicleType",
      filter: false,
    },
    {
      field: "customFields",
      header: "Campos Customizados",
      columnKey: "customFields",
      sortable: false,
      filter: false,
    },
  ],
  /*columns: [
    {
      field: "deliveryStatus",
      header: "Situação",
      columnKey: "deliveryStatus",
      sortable: false,
      filter: false,
    },
    {
      field: "occurrencesQuantity",
      header: "Qt ocorrências",
      columnKey: "occurrencesQuantity",
      sortable: true,
      filter: true,
    },
    {
      field: "number",
      header: "Número",
      columnKey: "number",
      sortable: true,
      filter: true,
    },
    {
      field: "serie",
      header: "Série",
      columnKey: "serie",
      sortable: true,
      filter: true,
    },
    {
      field: "issueDate",
      header: "Dt Emissão",
      columnKey: "issueDate",
      sortable: true,
      filter: true,
    },
    {
      field: "deliveryDeadlineDate",
      header: "Dt Previsão",
      columnKey: "deliveryDeadlineDate",
      sortable: true,
      filter: true,
    },
    {
      field: "deliveryDate",
      header: "Dt Entrega",
      columnKey: "deliveryDate",
      sortable: false,
      filter: false,
    },
    {
      field: "receiver",
      header: "Destinatário",
      columnKey: "receiver",
      sortable: true,
      filter: false,
    },
    {
      field: "destination",
      header: "Destino",
      columnKey: "destination",
      sortable: true,
      filter: false,
    },
    {
      field: "carrier.person.name",
      header: "Transportadora",
      columnKey: "carrier.person.cnpj",
      sortable: true,
      filter: false,
    },
  ],*/
  modalDeliveryDate: {
    visible: false,
    modal: true,
  },
  modalCubageAndVehicleType: {
    visible: false,
    modal: true,
  },
  trackingFilter: {
    totalOcccurrences: "",
    initialDateFilter: moment().startOf("month").toDate(),
    finalDateFilter: moment().toDate(),
    carrierFilter: {
      oid: null,
      documentNumber: "",
      name: "",
      tradeName: "",
    },
    nfeNumberFilter: null,
    lookupCarrier: {
      visible: false,
      modal: false,
      header: "Transportadora",
    },
    containCustomField: true,
    customFields: null
  },
  lookupOccurrenceType: {
    visible: false,
    modal: false
  },
  occurrenceTypeFilter:{
    oid: null,
    code: "01",
    description: "Entrega Realizada Normalmente",
    groupType: "",    
  },
  additionalDescription:"",
  baixaManual:false,
  currentRecord: {
    oid: null,
    number: "",
    serie: "",
    date: null,
    deliveryDate: null,
    carrier: null,
    value: 0,
    occurrences: [],
    cubage: 0,
    vehicleType: "",
  },
  selectedRow: null,
  selectCubageVehicleType: {
    nfeID: 0,
    cubage: { value: 0 },
    vehicleType: null,
  },
  deliveryStatusOptions: [
    { label: "Todos", value: null },
    { label: "No prazo", value: "IN_TIME" },
    { label: "Prazo vencendo hoje", value: "ON_TIME" },
    { label: "Prazo vencido", value: "LATE" },
    { label: "Entregue com atraso", value: "DELIVERED_LATE" },
    { label: "Entregue no prazo", value: "DELIVERED_IN_TIME" },
    { label: "Cancelada", value: "DELIVERY_CANCELED" },
    { label: "Baixa Manual", value: "BAIXA_MANUAL" },
  ],
  listVehiclesType: [],
  currentFilterDeliveryStatus: null,
  backupDeliveryDate: null,
  dateColumnFilter: {
    issueDate: null,
    deliveryDate: null,
    deliveryDeadlineDate: null,
  },
  modalCustomFields: {
    visible: false,
    modal: true,
  },
  recordWithCustomFields: {
    oid: 0,

    listCustomFields: [],
  },
};

const reducer = new Reducer(initialState, types);

export function TrackingReducer(state, action) {
  let result = state;
  if (action.type === types.SET_RECORD) {
    result = Object.assign({}, state, { currentRecord: action.record });
  } else if (action.type === types.SET_FIELD) {
    return setField(state, action);
  } else {
    result = reducer.execute(state, action);
  }
  result = execute(result, action);
  return result;
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_FIELD_TRACKING_FILTER:
      return setFieldTrackingFilter(state, action);
    case types.SET_FIELD_OCCURRENCE_TYPE_FILTER:
      return setFieldOccurrenceTypeFilter(state, action);
    case types.SET_ADDITIONAL_DESCRIPTION:
      return setAdditionalDesctiption(state, action);
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state, action);
    case types.SET_LOOKUP_OCCURENCE_TYPE_VISIBLE:
      return setlookupOccurrenceTypeVisible(state, action);
    case types.LOAD_OCCURRENCE_RECORDS:
      return loadOccurrenceRecords(state, action);
    case types.SET_CURRENT_FILTER_DELIVERY_STATUS:
      return setCurrentFilterDeliveryStatus(state, action);
    case types.SHOW_MODAL_DELIVERY_DATE_VISIBLE:
      return showModalDeliveryDateVisible(state, action);
    case types.BACKUP_DELIVERY_DATE:
      return backupDeliveryDate(state, action);
    case types.SELECT_RECORD:
      return selectedRecord(state, action);
    case types.SET_FIELD_SELECTED_RECORD:
      return setFieldSelectedRecord(state, action);
    case types.SET_DATE_COLUMN_FILTER:
      return setDateColumnFilter(state, action);
    case types.SET_VISIBLED_BTN_DOWNLOAD_CSV:
      return setVisibleBtnDownloadCSV(state, action);
    case types.SHOW_MODAL_CUBAGE_AND_VEHICLE_TYPE_VISIBLE:
      return showModalCubageAndVehicleTypeVisible(state, action);
    case types.SET_VEHICLE_TYPE:
      return setVehicleType(state, action);
    case types.SET_CUBAGE:
      return setCubage(state, action);
    case types.SET_NFE_ID:
      return setNFeID(state, action);
    case types.SET_LIST_VEHICLES_TYPE:
      return setListVehiclesType(state, action);
    case types.SHOW_MODAL_CUSTOM_FIELDS:
      return showModalCustomFields(state, action);
    case types.SET_RECORD_CUSTOM_FIELDS:
      return setRecordWithCustomFields(state, action);
    case types.SET_CUSTOM_FIELDS:
      return setCustomFields(state, action);
    case types.ON_REMOVE_CUSTOM_FIELDS:
      return removeItemCustomFieldsHandler(state, action);
    case types.ON_ADD_CUSTOM_FIELDS:
      return onAddNewCustomFieldsHandler(state, action);
    case types.SET_BAIXA_MANUAL:
      return setBaixaManual(state, action);
    default:
      return result;
  }
}
function setBaixaManual(state, action) {
  let baixaManual = action.baixaManual; 
  return { ...state, baixaManual }
}
function removeItemCustomFieldsHandler(state, action) {
  let recordWithCustomFields = state.recordWithCustomFields

  if (!recordWithCustomFields.listCustomFields)
    recordWithCustomFields.listCustomFields = [];

  if (recordWithCustomFields.listCustomFields && recordWithCustomFields.listCustomFields.length > 0) {
    recordWithCustomFields.listCustomFields = recordWithCustomFields.listCustomFields.filter(item => item.uuid != action.item.uuid)
  }

  if (recordWithCustomFields.listCustomFields.length == 0) {
    recordWithCustomFields.listCustomFields.push({
      uuid: crypto.randomUUID(),
      campo: "",
      value: "",
    });
  }

  return { ...state, recordWithCustomFields }
}

function onAddNewCustomFieldsHandler(state, action) {
  let recordWithCustomFields = state.recordWithCustomFields;
  if (!recordWithCustomFields) {
    recordWithCustomFields = {
      listCustomFields: []
    }
  }

  let listCustomFields = recordWithCustomFields.listCustomFields
  if (!listCustomFields)
    listCustomFields = [];

  listCustomFields.push({
    uuid: crypto.randomUUID(),
    campo: "",
    value: "",
  });
  recordWithCustomFields.listCustomFields = listCustomFields;
  return { ...state, recordWithCustomFields }
}

function setCustomFields(state, action) {
  let recordWithCustomFields = state.recordWithCustomFields;
  if (!recordWithCustomFields) {
    recordWithCustomFields = {
      listCustomFields: []
    }
  }

  let listCustomFields = recordWithCustomFields.listCustomFields
  if (!listCustomFields)
    listCustomFields = [];
  for (let i = 0; i < listCustomFields.length; i++) {
    if (listCustomFields[i].uuid == action.uuid) {
      listCustomFields[i][action.field] = action.value;
    }
  }
  recordWithCustomFields.listCustomFields = listCustomFields;
  return { ...state, recordWithCustomFields }
}

function setRecordWithCustomFields(state, action) {
  let recordWithCustomFields = action.data;
  if (recordWithCustomFields) {
    const listCustomFields = []
    if (recordWithCustomFields) {
      for (const [key, value] of Object.entries(recordWithCustomFields.customFields)) {
        listCustomFields.push({
          uuid: crypto.randomUUID(),
          campo: key,
          value: value,
        });
      }
    }
    if (listCustomFields.length == 0) {
      listCustomFields.push({
        uuid: crypto.randomUUID(),
        campo: "",
        value: "",
      });
    }

    recordWithCustomFields["listCustomFields"] = listCustomFields;
  }
  return { ...state, recordWithCustomFields }
}
function showModalCustomFields(state, action) {
  let modalCustomFields = state.modalCustomFields;
  modalCustomFields.visible = action.visible;
  return { ...state, modalCustomFields }
}


function setVisibleBtnDownloadCSV(state, action) {
  state = Object.assign({}, state, { disabledBtnDownloadCSV: action.value });
  return state;
}

function setVehicleType(state, action) {
  let selectCubageVehicleType = state.selectCubageVehicleType;
  selectCubageVehicleType.vehicleType = action.value;
  return { ...state, selectCubageVehicleType };
}
function setCubage(state, action) {
  let selectCubageVehicleType = state.selectCubageVehicleType;
  selectCubageVehicleType.cubage = action.value;
  return { ...state, selectCubageVehicleType };
}
function setNFeID(state, action) {
  let selectCubageVehicleType = state.selectCubageVehicleType;
  selectCubageVehicleType.nfeID = action.value;
  return { ...state, selectCubageVehicleType };
}
function setListVehiclesType(state, action) {
  let listVehiclesType = state.listVehiclesType;
  listVehiclesType = action.data;
  return { ...state, listVehiclesType };
}

function setDateColumnFilter(state, action) {
  let dateColumnFilter = state.dateColumnFilter;
  dateColumnFilter = Object.assign({}, dateColumnFilter, {
    [action.field]: action.value,
  });
  return { ...state, dateColumnFilter };
}

function selectedRecord(state, action) {
  const selectedRow = action.record;
  return { ...state, selectedRow };
}

function backupDeliveryDate(state, action) {
  const backupDeliveryDate = action.deliveryDate;
  return { ...state, backupDeliveryDate };
}

function showModalDeliveryDateVisible(state, action) {
  let modalDeliveryDate = state.modalDeliveryDate || {};
  modalDeliveryDate.visible = action.visible;
  return Object.assign({}, state, { modalDeliveryDate });
}
function showModalCubageAndVehicleTypeVisible(state, action) {
  let modalCubageAndVehicleType = state.modalCubageAndVehicleType || {};
  modalCubageAndVehicleType.visible = action.visible;
  return Object.assign({}, state, { modalCubageAndVehicleType });
}

function setFieldSelectedRecord(state, action) {
  let selectedRow = state.selectedRow || {};
  const { field, value } = action;
  const fields = field && field.split(".");
  const lastField = fields[fields.length - 1];
  let record = selectedRow;
  if (fields.length > 1) {
    for (let i = 0; i < fields.length - 1; i++) {
      const owner = record;
      record = record[fields[i]];
      if (!record) {
        record = {};
        owner[fields[i]] = record;
      }
    }
  }
  record[lastField] = value;
  selectedRow = record;
  return Object.assign({}, state, { selectedRow });
}

function setField(state, action) {
  let currentRecord = state.currentRecord || {};
  const { field, value } = action;
  const fields = field && field.split(".");
  const lastField = fields[fields.length - 1];
  let current = currentRecord;
  if (fields.length > 1) {
    for (let index = 0; index < fields.length - 1; index++) {
      const owner = current;
      current = current[fields[index]];
      if (!current) {
        current = {};
        owner[fields[index]] = current;
      }
    }
  }

  current[lastField] = value;
  return Object.assign({}, state, { currentRecord });
}

function setLookupCarrierVisible(state, action) {
  let trackingFilter = state.trackingFilter;
  let lookupCarrier = trackingFilter.lookupCarrier;
  lookupCarrier = Object.assign({}, lookupCarrier, { visible: action.visible });
  trackingFilter = Object.assign({}, trackingFilter, { lookupCarrier });
  state = Object.assign({}, state, { trackingFilter });
  return state;
}
function setlookupOccurrenceTypeVisible(state, action) {
  let lookupOccurrenceType = state.lookupOccurrenceType
  lookupOccurrenceType.visible = action.visible
  state = { ...state, lookupOccurrenceType }
  return state;
}

function setFieldTrackingFilter(state, action) {
  let trackingFilter = state.trackingFilter;
  trackingFilter = Object.assign({}, trackingFilter, {
    [action.fieldName]: action.fieldValue,
  });
  state = Object.assign({}, state, { trackingFilter });
  return state;
}

function setFieldOccurrenceTypeFilter(state, action) {
  let occurrenceTypeFilter = state.occurrenceTypeFilter;
  occurrenceTypeFilter = action.fieldValue;
  return { ...state, occurrenceTypeFilter };
}
function setAdditionalDesctiption(state, action) {
  let additionalDescription = state.additionalDescription;
  additionalDescription = action.additionalDescription;
  return { ...state, additionalDescription };
}


function loadOccurrenceRecords(state, action) {
  let currentNFeRecord = state.currentNFeRecord;
  currentNFeRecord = Object.assign({}, currentNFeRecord, {
    occurrences: action.occurrenceRecords,
  });
  state = Object.assign({}, state, { currentNFeRecord });
  return state;
}

function setCurrentFilterDeliveryStatus(state, action) {
  state = Object.assign({}, state, {
    currentFilterDeliveryStatus: action.currentFilterDeliveryStatus,
  });
  return state;
}
